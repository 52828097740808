import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import LogoAgc from "./../images/anima-gc-square.png"

const Title = styled.h1`
  margin: 40px 0 0 20px;
  max-Width: 117px;
  font-Size: 1.4em;
  font-Weight: bold;
`;
const Logo = styled.img`
  margin: 0;
  max-Width: 170px;
  height: auto;
  max-Height: 170px
`;
const Container  = styled.div`
  margin: 0 auto;
  Width: 80vw;
  max-Width: 1280px;
  padding: 1.45rem 1.0875rem;
  max-Heighth: 150px
`;
const Wrapper = styled.div`
  display: flex;
  flex-Direction: row;
  flex-Wrap: nowrap;
  justify-Content: flex-end;
  align-Content: stretch;
`;

const Header = ({ siteTitle }) => (
  <header >
    <Container >
    <Wrapper>
    <Logo src={LogoAgc} alt="Logo" />
      <Title>
        <Link
          to="/"
          style={{
            color: `black`,
            textDecoration: `none`,
          }}
        >{siteTitle}
        </Link>
      </Title>
      </Wrapper>   </Container>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
