import React from "react"
import styled from "styled-components"

const Section = styled.div`
  margin: 0;
`;

const Container = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: stretch;
  align-items: flex-end;
`;

const Button = styled.a`
   margin: 0 15px;
   padding: 10px;
   text-transform: uppercase;
   text-decoration: none;
   color: #fff;
   font-weight:  600;
   font-size: 0.8em;
   background: #130048;
   border-radius: 4px;
   &:hover {
      color: #e92177;
      background: #130040;
    }
`;

const Nav = () => (
  <Section>
    <Container>
        <Button href="about">Info</Button>
        <Button href="https://www.linkedin.com/company/anima-gestion-cultural-sas/">Linkedin</Button>
    </Container>
  </Section>
);

export default Nav;
