/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Nav from './Nav'
import "./layout.css"
import styled from "styled-components"
import "@fontsource/open-sans"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/600.css" // Weight 500 with all styles included.
import "@fontsource/open-sans/800.css" // Select either normal or italic.
import LogoAgc from "./../images/anima-gc-square.png"

const Logo = styled.img`
  margin: 0;
  max-width: 100px;
`;
const FootWrapper = styled.div`
  width: 80vw;
  max-Width: 1920px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;
const Adress = styled.p`
  font-Size: .9em;
  color: #ccc;
  font-Weight: 600;
  padding: 0;
  margin: 0 25px;
`;
const Bg = styled.div`
  background-color: #130054;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
`;
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="site">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main  className="site-content">{children}</main>
        <footer style={{ background: "#130054", color:"#fff"}}>
        <Bg>
          <FootWrapper>
              <Logo src={LogoAgc} alt="Logo" />
              <Nav/>
              <Adress>Ánima Gestión Cultural SAS. <br/><a href="https://www.openstreetmap.org/node/944838742#map=19/-34.91024/-56.17813">Magallanes 1064, Mvd, Uruguay.</a></Adress>
          </FootWrapper>
          </Bg>
        </footer>
        </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
